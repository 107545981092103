import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';
import Main from './Main';

import IdeaViewText from './IdeaViewText';
import styles from './Main.module.css';






function App() {

const urlParams = new URLSearchParams(window.location.search);
const widget = urlParams.get('widget'); // 'egos'
const brain=urlParams.get('brain')
const personas=urlParams.get('personas')

const epochTimeInSeconds = Math.floor(new Date().getTime() / 1000);
//http://localhost:3000/?widget=ideaview&brain=ideacrit&personas=prof



const [config, setConfig] = useState(null);
  useEffect(() => {
    fetch(`/config.json?${epochTimeInSeconds}`)
      .then(response => response.json())
      .then(data => {
          setConfig(data.main)
        //console.log(data['main'].icebreaker); // Process your data here
      })
      .catch(error => {
        console.error('Error fetching config.json:', error);
      });
  }, []);

  return (
    <div className="App">

    <Main config={config}/>









   </div>
  );
}

export default App;
