import React, { useState, useEffect } from 'react';
import styles from './ShowPersona.module.css';

const ShowPersona = (props) => {
  const config = props.config;
  const personas = props.personas;
  const maskUrl = config.mask1;
  const maskbubble = config.maskbubble;

  const [personName, setPersonName] = useState(null);
  const [personBlurb, setPersonBlurb] = useState(null); // New state for blurb
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [selectedPersonName, setSelectedPersonName] = useState(null);
  const [selectedPersonBlurb, setSelectedPersonBlurb] = useState(null); // New state to hold the selected person's blurb

  // Initial random selection
  useEffect(() => {
    if (personas.staff.length > 0) {
      const randomIndex = Math.floor(Math.random() * personas.staff.length);
      setSelectedMemberIndex(randomIndex);
      const initialName = personas.staff[randomIndex].name;
      const initialBlurb = personas.staff[randomIndex].blurb; // Assuming each member has a 'blurb' field
      setPersonName(initialName);
      setPersonBlurb(initialBlurb); // Set initial blurb based on random selection
      setSelectedPersonName(initialName);
      setSelectedPersonBlurb(initialBlurb); // Also update selectedPersonBlurb
      props.onSelectedMemberChange(randomIndex);

    }
  }, [personas.staff.length]);

  const handleClick = (member, index) => {
    setPersonName(member.name);
    setPersonBlurb(member.blurb); // Update the displayed blurb to the clicked member
    setSelectedPersonName(member.name);
    setSelectedPersonBlurb(member.blurb); // Update selectedPersonBlurb for restoration after hover out
    setSelectedMemberIndex(index);
    props.onSelectedMemberChange(index);
  };

  const handleMouseEnter = (member, index) => {
    if (index !== selectedMemberIndex) {
      setHoverIndex(index);
      setPersonName(member.name); // Temporarily update personName to hovered member's name
      setPersonBlurb(member.blurb); // Temporarily update personBlurb to hovered member's blurb
    }
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
    setPersonName(selectedPersonName); // Restore personName to the selected member's name upon hover out
    setPersonBlurb(selectedPersonBlurb); // Restore personBlurb to the selected member's blurb upon hover out
  };

  return (
    <div className={styles.bg} style={{ backgroundColor: config.personabg }}>
      {personas.staff.map((member, index) => (
        <div key={index}
             style={{ position: 'relative', display: 'inline-block' }}
             onClick={() => handleClick(member, index)}
             onMouseEnter={() => handleMouseEnter(member, index)}
             onMouseLeave={handleMouseLeave}
        >
          
        </div>
      ))}


    </div>
  );
}

export default ShowPersona;
