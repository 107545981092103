import React, { useState, useEffect, useRef } from 'react';
import styles from './TextToSpeechPlayer.module.css';

import ui_download from './ui_download.svg';

const TextToSpeechPlayer = ({ bear, fullResponse, bgcolor, textcolor, isButtonDisabled, firstClick, voice, movedownloadStyle }) => {
  const [buttonText, setButtonText] = useState('Listen To Audio');
  const audioUrlRef = useRef(null); // Holds the audio URL
  const audioRef = useRef(new Audio()); // Holds the audio object
  const lastFetchedTextRef = useRef(""); // Track the last fetched text
  const [isFetching, setIsFetching] = useState(false); // Track fetching state
  const [audioReady, setAudioReady] = useState(false); // NEW: Track if audio is ready

  const handleButtonClick = () => {
    if (isFetching) return; // Do nothing if already fetching
    if (audioRef.current.paused && lastFetchedTextRef.current === fullResponse && audioReady) {
      // If audio is paused and the text hasn't changed and audio is ready, resume playing
      audioRef.current.play();
      setButtonText('Pause');
    } else if (!audioRef.current.paused) {
      // If audio is playing, pause it
      audioRef.current.pause();
      setButtonText('Play Audio');
    } else {
      // If the text is new, fetch and play it
      fetchAndPlaySpeech();
    }
  };

  const handleDownloadClick = () => {
    // Create a temporary anchor element and trigger the download
    const link = document.createElement("a");
    link.href = audioUrlRef.current;
    link.download = "audiostream.mp3"; // You can name the file anything you want
    document.body.appendChild(link); // Append to html page
    link.click(); // Simulate click to trigger download
    link.remove(); // Clean up
  };

  const fetchAndPlaySpeech = async () => {
    setIsFetching(true);
    setAudioReady(false); // Reset audio ready state
    setButtonText('Loading...');
    try {
      const response = await fetch('https://api.openai.com/v1/audio/speech', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bear}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          model: "tts-1",
          input: fullResponse,
          voice: voice
        })
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob();
      audioUrlRef.current && URL.revokeObjectURL(audioUrlRef.current); // Clean up the previous audio URL
      audioUrlRef.current = URL.createObjectURL(blob); // Store the new audio URL
      lastFetchedTextRef.current = fullResponse; // Update last fetched text
      audioRef.current.src = audioUrlRef.current;
      audioRef.current.play();
      audioRef.current.onended = () => setButtonText('Play Audio');
      setButtonText('Pause');
      setAudioReady(true); // Set audio as ready
    } catch (error) {
      console.error('Error fetching audio:', error);
      setButtonText('Play Audio');
    } finally {
      setIsFetching(false);
    }
  };

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      audioRef.current.pause();
      if (audioUrlRef.current) {
        URL.revokeObjectURL(audioUrlRef.current);
      }
    };
  }, []);

  return (
    <div className={styles.toolbar} style={{backgroundColor: bgcolor}}>
      {firstClick && !isButtonDisabled && <>
        <div onClick={handleButtonClick} disabled={isFetching} className={styles.button}>
          <span style={{color:`${textcolor}`}}>{buttonText}</span>
        </div>
        {audioReady && <div onClick={handleDownloadClick} className={styles.download} style={movedownloadStyle}>
          <img src={ui_download} className={styles.ui_download}/>
        </div>}
      </>}
    </div>
  );
};

export default TextToSpeechPlayer;
