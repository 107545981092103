import React, { useEffect, useRef, useState  } from 'react';
import ring from './ring.svg';


import styles from './Search.module.css';
import ShowPersona from './ShowPersona';
import TextToSpeechPlayer from './TextToSpeechPlayer';

const Search = (props) => {
  const config = props.config;
  const personas = props.personas;
  const [joke, setJoke] = useState(config.start);
  const [runningJoke, setRunningJoke] = useState("");
  const placehHolder= config.placeholder;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [firstClick, setFirstClick] = useState(false);
  const topicRef = useRef(null);
  const resultDiv = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageReduced, setImageReduced] = useState(null);
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(0);
  const [currentPersona, setCurrentPersona] = useState(null);
  const [postItIndex, setPostItIndex]=useState(null)
  const [responseArray, setResponseArray]=useState([])
  const [fullResponse, setFullResponse]=useState(null)
  const bear='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'


/* scrollbar */
  useEffect(() => {
    const handleResize = () => {
      if (threadRef.current) {
        const isScrollable = threadRef.current.offsetHeight > window.innerHeight - 400;
        threadRef.current.classList.toggle(styles.scrollable, isScrollable);
      }
    };

    // Call handleResize on mount and on every window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
      if (threadRef.current) {
        threadRef.current.scrollTop = threadRef.current.scrollHeight;
        threadRef.current.style.setProperty('--scrollbar-track-color', config.scrolltrack);
        threadRef.current.style.setProperty('--scrollbar-thumb-color', config.scrollthumb);
      }
    }, [joke]);

/* scrollbar */
//chat scrolling
const [useScrollClass, setUseScrollClass] = useState(false);
    const contentRef = useRef(null);
    const contentRef2 = useRef(null);
    const threadRef=useRef(null);



    /*useEffect(() => {
     const checkContentHeight = () => {
      const element = contentRef.current;
      const element2 = contentRef2.current; // Correctly reference contentRef2
      if (element && element2) {
        setUseScrollClass(element2.scrollHeight > element.clientHeight);
      }
    };

    checkContentHeight();

    return () => {
    //  window.removeEventListener('resize', checkContentHeight);
    };
  }, [joke]);*/

  const className = useScrollClass
       ? `${styles.rightColumnChat} ${styles.scrollableDiv}` // Apply scrollableDiv class additionally if true
       : `${styles.rightColumnChatCenter}`;

  //end chat scroll

  //scrollbar colors
  //document.documentElement.style.setProperty('--scrollbar-track-color', config.scrolltrack);
  //document.documentElement.style.setProperty('--scrollbar-thumb-color', config.scrollthumb);

  useEffect(() => {
      if (threadRef.current) {
        threadRef.current.style.setProperty('--scrollbar-track-color', config.scrolltrack);
        threadRef.current.style.setProperty('--scrollbar-thumb-color', config.scrollthumb);
      }
    }, [config.scrolltrack, config.scrollthumb]);  // Depend on config values to update

  //document.documentElement.style.setProperty('--scrollbar-height', config.chatheight);
  //document.documentElement.style.setProperty('--placeholdercolor', config.placeholdercolor);

  //end scrollbar colors

  const movedownloadStyle = {
    top:'5px',
    right: '30px',
    left: 'auto',
  };


  const handleSelectedMemberChange = (index) => {
  setSelectedMemberIndex(index);
  let name =personas.staff[index].name
  let blurb =personas.staff[index].blurb
  let pbackground=personas.staff[index].background
  let persona=personas.staff[index].persona
  let communication_style=personas.staff[index].communication_style
  let quirks=personas.staff[index].quirks
  let influence=personas.staff[index].influence
  let teaching_style=personas.staff[index].teaching_style
  let jargon_examples=personas.staff[index].jargon_examples
  let jargon_amount=personas.staff[index].jargon
  let guidance=config.guidance
  let profile=`${name} is ${blurb} whose backgorund is ${pbackground} this is at the core of who they are ${persona}.
  Their influnces that shape who they are and how they respond are ${influence}. The way they like to respond is ${communication_style}. Their personality quarks are ${quirks}. The type of jargon they will use
  in their response ${jargon_amount} of the time includes ${jargon_examples}, but the jargon can be varisions of this. ${guidance}`
  setCurrentPersona(profile)

};




const fileInputRef = useRef(null);


  const fetchJoke = () => {
  let topic = topicRef.current.value;


     let content = `${config.ask} persona: ${currentPersona}. ${config.post_ask}. Have the persona respond to the following: `;

    if (topic) {
      content += ` ${config.otherthought} ${topic}.`;
    }

    if (imagePreview !== null) {
      content += " Also align the idea with elements of the included image. ";
    }

    setIsButtonDisabled(true); // Disable button before fetch
    setFirstClick(true)
    setJoke(config.fetching);




    const textContent = {
      type: "text",
      text: content
    };

    // Initialize content with just the text part
    let messagecontent = [textContent];

    // Conditionally add the image content if 'imagePreview' is not null
    if (imagePreview !== null) {
      const imageContent = {
        type: "image_url",
        image_url: {
          url: `${imageReduced} `// Use the image URL or Base64 string from 'imagePreview'
        }
      };
      // Add the imageContent to the content array
      messagecontent.push(imageContent);
    }


    //gpt-4-turbo-preview

    //let openaimodel="gpt-4-turbo-preview"
      let openaimodel="gpt-4-turbo-2024-04-09"

    if(imagePreview!=null){
      openaimodel="gpt-4-vision-preview"
    }

    fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bear}` // Replace with your actual API key
        },
        body: JSON.stringify({
          model: openaimodel,
          messages: [{ role: "user", content: messagecontent }]
        })
      })
      .then(response => response.json())
      .then(data => {

      const jsonResponse = data.choices[0].message.content;
      setFullResponse(jsonResponse)
  try {
    //const correctJsonString = jsonResponse.replace(/```json\n|\n```/g, '').trim();
    //const idea = JSON.parse(correctJsonString);


    if(config.chatboxscroll){
      let allResponse = processResponseSimple(jsonResponse);
      //console.log(allResponse)

        let breaker = '<div style="width: 100%; height: 1px; background-color: rgb(10,10,10); margin-left:auto; margin-right:auto; margin-top:30px; margin-bottom:30px;"></div>';
         if(!firstClick){
            breaker="<div><div>"
         }
         setJoke(allResponse);
         setRunningJoke(prevrunningJoke => `${prevrunningJoke} ${breaker} ${allResponse}`);
    }else{
     let firstResponse=processResponse(jsonResponse)
      setJoke(firstResponse);
    }


    //setJoke(jsonResponse)

    //console.log(idea)
  } catch (error) {
    console.error('Error parsing JSON:', error);
    setJoke("Error parsing response.");
  }
})

      .finally(() => {
        setIsButtonDisabled(false); // Re-enable button after fetch
        setPostItIndex(0)
      });




  };

  function processResponseSimple(response) {
    // Split the response into sentences based on periods followed by space or end of the string
    const sentences = response.split(/(?<=\.)(\s|$)/);

  // Iterate over each sentence
  for (let i = 0; i < sentences.length; i++) {
    // Generate a random number, either 0 or 1
    const randomNumber = Math.floor(Math.random() * 2);

    // Check if the sentence ends with one or more <br><br>
    if (randomNumber === 1 && !/(\s|<br>)*<br><br>$/.test(sentences[i])) {
      // Append <br><br> only if it's not already there
      sentences[i] = sentences[i].trim() + '<br><br>';
    }
  }

  // Join the processed sentences back into a single string
  let finalResponse = sentences.join('');

  // Replace occurrences of four <br> tags with just two
  finalResponse = finalResponse.replace(/(<br><br>)+/g, '<br><br>');

  return finalResponse;
  }

/*  function processResponseSimple(response) {
    const processedResponse = response.replace(/\.(\s|$)/g, '.<br><br>$1');
    return processedResponse;
  }*/

  function processResponse(response) {
    const MAX_LENGTH = 250; // Maximum length of each fragment
    const words = response.split(/\s+/); // Split the response into words

    let chunks = []; // Initialize an empty array to hold the fragments
    let currentChunk = '';

    words.forEach(word => {
      // Check if adding the next word would exceed the MAX_LENGTH
      if ((currentChunk.length + word.length + 1) <= MAX_LENGTH) {
        currentChunk += (currentChunk.length > 0 ? ' ' : '') + word; // Add the word to the current chunk
      } else {
        chunks.push(currentChunk); // Add the current chunk to the chunks array
        currentChunk = word; // Start a new chunk with the current word
      }
    });

    // Don't forget to add the last chunk if it's not empty!
    if (currentChunk.length > 0) {
      chunks.push(currentChunk);
    }

    // Add <br><br> after every line ending with a period.
    chunks = chunks.map(chunk => chunk.replace(/\.(\s|$)/g, '.<br><br>$1'));

   if (chunks.length > 1) {
      chunks = chunks.map((chunk, index) => index !== chunks.length - 1 ? chunk + '...' : chunk);
      console.log(chunks)
    }

     setResponseArray(chunks);
     return chunks[0];
  }

 const nextNote = ()=>{

    let temp_postItIndex=postItIndex+1
    temp_postItIndex=temp_postItIndex%responseArray.length
    setPostItIndex(temp_postItIndex)
      setJoke(responseArray[temp_postItIndex]);


 }



  /*dropZone*/
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };


  const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file && file.type.startsWith('image/')) { // Broadened to include all image types
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      resizeImage(img)



    };

    reader.readAsDataURL(file);
  }
};

function resizeImage(img){

  let newsize=150
  img.onload = () => {
    let width = img.width;
    let height = img.height;


   //Convert Original Image //
    const canvasOrig = document.createElement('canvas');
    const ctxOrig = canvasOrig.getContext('2d');
    canvasOrig.width = width;
    canvasOrig.height = height;
    ctxOrig.drawImage(img, 0, 0, width, height);
    const originalImageURL = canvasOrig.toDataURL('image/png');



    // Resize Original Image if necessary //
    if (width > newsize || height > newsize) {
      const aspectRatio = width / height;

      if (width > height) {
        width = newsize;
        height = width / aspectRatio;
      } else {
        height = newsize;
        width = height * aspectRatio;
      }
    }

     // Resize Original Image if necessary //
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);

    const resizedImageURL = canvas.toDataURL('image/png');

    setImageReduced(resizedImageURL)
    setImagePreview(originalImageURL);

  };
}

const handleDragOver = (event) => {
event.preventDefault();
};

const handleDrop = (event) => {
 event.preventDefault();
 const file = event.dataTransfer.files[0];

   //if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
   if (file && file.type.startsWith('image/')) { // Broadened to include all image types
     const reader = new FileReader();

     reader.onload = (e) => {
       const img = new Image();
       img.src = e.target.result;
       resizeImage(img)



     };

     reader.readAsDataURL(file);
   }
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault(); // Prevent default Enter key behavior (e.g., form submission)
    fetchJoke();
  }
};


  return (
    <div className={styles.center_container}>


      {!<div className={styles.container} style={{ color: config.textcolor, backgroundColor: config.backgroundcolor, boxShadow: config.shadow, height:config.containerheight, width:config.containerwidth }}>
        <div className={styles.footbg}
        style={{background:config.bgfoot}}></div>

        <div className={styles.bglayer} style={{
          backgroundImage: `url(${config.bgmain})`,
          backgroundSize: 'cover',
          backgroundPosition: `${config.bgmainPosition}`,
          width: `${config.bgmainWidthHeight}`, // Example width
          height: `${config.bgmainWidthHeight}`, // Example height
          backgroundRepeat: 'no-repeat',
          opacity:`${config.bgmainOpacity}`
        }}></div>

        {/* HEAD */}
        <div className={styles.row} style={{
          backgroundImage: `url(${config.banner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          width: '100%', // Example width
          backgroundRepeat: 'no-repeat'
        }}>
          <div className={styles.headbg} style={{background:config.bghead}}></div>

          {config.title &&<div className={styles.title} style={{color:config.titleColor,
            textShadow:config.titleShadow,
            WebkitTextStrokeWidth:config.titleStrokeWidth,
            WebkitTextStrokeColor:config.titleStrokeColor,
            fontSize:config.titleSize,

          }} dangerouslySetInnerHTML={{ __html: config.title }}></div>}
          {config.subtitle && <div className={styles.subtitle} style={{
            color:config.subtitleColor,
            textShadow:config.subtitleShadow,
            fontSize:config.subtitleSize,
          }}>{config.subtitle}</div>}
          {config.spacetop &&<div style={{height:config.spacetop}}></div>}
        </div>

        {/* IMAGE */}
        <div className={styles.rowtop}>
          {config.includedropzone && <div className={styles.leftColumn}>
            <div className={styles.dropzoneHolder} onClick={triggerFileInput}>
              <label htmlFor="file" className={styles.dropZone} onDrop={handleDrop} onDragOver={handleDragOver} style={{ color: config.textcolor, backgroundColor: config.backgroundcolor, border: 'solid 1px', borderColor: config.dropzoneborder, width: '260px', height: '260px' }}>
                {imagePreview ? (
                  <img src={imagePreview} alt="Selected" className={styles.inputImg}/>
                ) : (
                  <div className={styles.dropzoneHolder} style={{ whiteSpace: 'pre-wrap' }}>Drop Image (Optional)</div>
                )}
              </label>
              <input ref={fileInputRef} type="file" accept="image/*" onChange={handleFileChange} style={{ display: 'none' }}/>
            </div>
          </div>}

          {/* right side */}
          <div ref={contentRef} className={className} style={{position:'relative', backgroundColor: config.chatcolorbg,  borderRadius:config.chatRadius,  height:config.chatheight, left:config.chatboxleft, marginLeft:"15px", marginLeft:config.chatmLeft, marginRight:config.chatmLeft}}>
               {/* inner content */}


              <div ref={contentRef2} dangerouslySetInnerHTML={{ __html: joke }} className={firstClick ? styles.chat : `${styles.chat} ${styles.firstClickChat}`} style={{color: config.chatcolor, fontSize:config.chatsize}}></div>
              {isButtonDisabled && <img src={ring} className={styles.ring} alt="Ring" />}


               {/* inner content */}


         </div>


          {/* end of right side */}
        </div>
        <div className={`${styles.tts} ${styles.ttsFull}`} >
          <div className={styles.tts2} style={{left:config.chatuiLeft}}>
          <TextToSpeechPlayer bear={bear} fullResponse={fullResponse} voice={personas.staff[selectedMemberIndex].voice} isButtonDisabled={isButtonDisabled} firstClick={firstClick} textcolor={config.chatui} movedownloadStyle={movedownloadStyle}/>
           </div>
        </div>

          {/* end of row */}

        {/* First Row */}
        <div className={styles.rowright} style={{height:config.spacebelowchat}}>


        </div>

        {/* persona */}
        <div className={styles.rowcenter} style={{justifyContent:'space-between',padding:'20px'}}>
          <div className={styles.personaui}>
            <ShowPersona
               config={config}
               personas={personas}
                onSelectedMemberChange={handleSelectedMemberChange}
               />
          </div>

        </div>}
        {/* end persona */}



        <div className={styles.rowtop}>
           <div className={styles.leftColumn}></div>
            <div className={styles.rightColumn2}>
               <button className={styles.button_6} onClick={fetchJoke} disabled={isButtonDisabled} style={{ left:config.buttonleft, color: config.buttontextcolor, backgroundColor: config.buttonbg, height:'30px' }}>{config.button}</button>
               <div className={styles.sponsor}>{config.sponsor && <img src={config.sponsor} style={{height:config.sponsorsizesmall, paddingTop:config.sponsorsizesmalltop, paddingRight:config.sponsorsizesmalright}}/>}</div>
            </div>
        </div>

      </div>}


  {/*new */}

        <div className={`${styles.thread}`} ref={threadRef}  dangerouslySetInnerHTML={{ __html: runningJoke }}></div>
        {isButtonDisabled  && <div ref={contentRef} className={className} style={{position:'relative', backgroundColor: config.chatcolorbg,  borderRadius:config.chatRadius,  height:config.chatheight, left:config.chatboxleft, marginLeft:"15px", marginLeft:config.chatmLeft, marginRight:config.chatmLeft}}>
        <div ref={contentRef2} dangerouslySetInnerHTML={{ __html: joke }} className={firstClick ? styles.chat : `${styles.chat} ${styles.firstClickChat}`} style={{color: config.chatcolor, fontSize:config.chatsize}}></div>
          {isButtonDisabled && <img src={ring} className={styles.ring} alt="Ring" />}
         </div>}

        <div className={styles.searchbox}>

            <input type="text" className={styles.inputfield} placeholder="Ask..." ref={topicRef}  onKeyDown={handleKeyDown}/>
            <div className={!isButtonDisabled ? styles.square : styles.squarenot} onClick={fetchJoke} disabled={isButtonDisabled} ></div>

        </div>
  {/*new */}

    </div>

  );
};

export default Search;
